import { useContext, useEffect } from 'react';
import { createContext, useState } from 'react'
import { ZIM } from 'zego-zim-web';
const ZIMContext = createContext(null);


export default function ZIMProvider({ children }) {
    const [zim, setZim] = useState(null);
    const zegoCloudAppId = process.env.REACT_APP_ZEGO_CLOUD_APP_ID;
    const zegoCloudServerUrl = process.env.REACT_APP_ZEGO_CLOUD_SERVER_URL;

    async function initZimSDK() {        
        const zim = await ZIM.create({ appID: Number(zegoCloudAppId) })
        console.log(zim,"??????????")
        if (!zim) {
            console.log("Zim not intialize");
            return

        }
        setZim(zim);
    }


    useEffect(() => {
        initZimSDK();
    }, [])


    return <ZIMContext.Provider value={{ zimInstance: zim }}>
        {children}
    </ZIMContext.Provider>
}


export function useZim() {
    const context = useContext(ZIMContext);
    if (!context) {
        throw new Error('useZim must be used within a ZIMProvider');
    }
    return context;
}