export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const REMOVE_LOGGED_IN_USER = "REMOVE_LOGGED_IN_USER";
export const PROFILE_ACTION = "PROFILE_ACTION";
export const SET_LOGGED_USER_DATA = "SET_LOGGED_USER_DATA";
export const CONNECT_WALLET_TOGGLE_ACTION = "CONNECT_WALLET_TOGGLE_ACTION";
export const USER_WALLET_ACTIONS = "USER_WALLET_ACTIONS";
export const RELOAD_API_AFTER_BET_ACTIONS = "RELOAD_API_AFTER_BET_ACTIONS";
export const RELOAD_WALLET_ACTIONS = "RELOAD_WALLET_ACTIONS";
export const HOST_ZOOM_ACTION = "HOST_ZOOM_ACTION";
export const TON_LOGGED_ACTION = "TON_LOGGED_ACTION";
export const APP_LANCHED_ACTION = "APP_LANCHED_ACTION";
export const ADD_PURCHASE_ITEMS_DATA = "ADD_PURCHASE_ITEMS_DATA";
export const MOBILE_NUMBER_ACTION = "MOBILE_NUMBER_ACTION";
export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";

export const HOST_START_LIVE = "HOST_START_LIVE";
export const HOST_VIDEO_MUTE = "HOST_VIDEO_MUTE";
export const HOST_AUDIO_MUTE = "HOST_AUDIO_MUTE";
export const HOST_VIDEO_CAMERA_FRONT = "HOST_VIDEO_CAMERA_FRONT";
export const SET_CLUB_CONTROL = "SET_CLUB_CONTROL";
export const AUDIENCE_JOINED = "AUDIENCE_JOINED";
export const AUDIENCE_AUDIO_MUTE = "AUDIENCE_AUDIO_MUTE";
export const AUDIENCE_VIDEO_MUTE = "AUDIENCE_VIDEO_MUTE";
export const AUDIENCE_CO_HOST_REQUEST = "AUDIENCE_CO_HOST_REQUEST";
export const PUSH_MSG = "PUSH_MSG";
export const RESET_STREAMING_AND_CHAT = "RESET_STREAMING_AND_CHAT";


export const HOST_COMP_REF = "HOST_COMP_REF"

export const OPEN_AUDIENCE_REQUEST_POPUP = "OPEN_AUDIENCE_REQUEST_POPUP";
export const CO_HOST_REQUESTS = "CO_HOST_REQUESTS";
export const CO_HOST_SEND_PROPOSAL = 'CO_HOST_SEND_PROPOSAL'
export const IS_HOST_LIVE = 'IS_HOST_LIVE'
export const IS_SCREEN_SHARE = 'IS_SCREEN_SHARE'
export const JOIN_USERS = 'JOIN_USERS'
export const SET_USER_BALANCE = 'SET_USER_BALANCE'