import { ZegoSignalingAction } from "core/helper/constant";
import {
  PROFILE_ACTION,
  HOST_START_LIVE,
  HOST_AUDIO_MUTE,
  HOST_VIDEO_MUTE,
  HOST_VIDEO_CAMERA_FRONT,
  SET_CLUB_CONTROL,
  AUDIENCE_JOINED,
  AUDIENCE_AUDIO_MUTE,
  AUDIENCE_CO_HOST_REQUEST,
  HOST_ZOOM_ACTION,
  PUSH_MSG,
  RESET_STREAMING_AND_CHAT,
  HOST_COMP_REF,
  OPEN_AUDIENCE_REQUEST_POPUP,
  CO_HOST_REQUESTS,
  CO_HOST_SEND_PROPOSAL,
  AUDIENCE_VIDEO_MUTE,
  IS_HOST_LIVE,
  IS_SCREEN_SHARE,
  JOIN_USERS
} from "../constant";

const initialState = {
  reloadProfile: false,
  isClubControlFor: "",
  hostStartLive: false,
  hostAudioMute: false,
  hostVideoMute: false,
  hostVideoCameraFront: false,
  audienceJoined: false,
  audienceAudioMute: false,
  audienceVideoMute: false,
  audienceCoHostRequested: false,
  zoomIn: false,
  messageArr: [],
  openAudienceRequestPopup: false,
  coHostPendingList: [],
  isProposalSend: false,
  isHostLive: false,
  isScreenSharing: false,
  joinUsers: []
};

const common = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_ACTION:
      return {
        ...state,
        reloadProfile: !state.reloadProfile,
      };
    case SET_CLUB_CONTROL:
      return {
        ...state,
        isClubControlFor: payload,
      };
    case HOST_START_LIVE:
      return {
        ...state,
        hostStartLive: payload,
      };
    case HOST_AUDIO_MUTE:
      return {
        ...state,
        hostAudioMute: payload,
      };
    case HOST_VIDEO_MUTE:
      return {
        ...state,
        hostVideoMute: payload,
      };
    case HOST_VIDEO_CAMERA_FRONT:
      return {
        ...state,
        hostVideoCameraFront: payload,
      };
    case AUDIENCE_JOINED:
      return {
        ...state,
        audienceJoined: payload,
      };
    case AUDIENCE_AUDIO_MUTE:
      return {
        ...state,
        audienceAudioMute: payload,
      };
    case AUDIENCE_VIDEO_MUTE:
      return {
        ...state,
        audienceVideoMute: payload,
      };
    case AUDIENCE_CO_HOST_REQUEST:
      return {
        ...state,
        audienceCoHostRequested: payload,
      };
    case HOST_ZOOM_ACTION:
      return {
        ...state,
        zoomIn: payload,
      };
    case RESET_STREAMING_AND_CHAT:
      return {
        ...state,
        isClubControlFor: "",
        hostStartLive: false,
        hostAudioMute: false,
        hostVideoMute: false,
        hostVideoCameraFront: false,
        audienceJoined: false,
        audienceAudioMute: false,
        audienceCoHostRequested: false,
        zoomIn: false,
        messageArr: [],
        hostCompRef: null,
        openAudienceRequestPopup: false,
        isProposalSend: false,
        isHostLive: false,
        isScreenSharing: false,
        joinUsers: []

      };
    case PUSH_MSG:
      state.messageArr.push(action.payload);
      return {
        ...state,
        messageArr: [...state.messageArr],
      };
    case HOST_COMP_REF:
      return {
        ...state,
        hostCompRef: payload
      };
    case OPEN_AUDIENCE_REQUEST_POPUP:
      return {
        ...state,
        openAudienceRequestPopup: payload
      };

    case CO_HOST_REQUESTS:
      return {
        ...state,
        coHostPendingList: (() => {
          console.log(payload, "playload")
          // Extract the current list and the new payload from state and action
          const { coHostPendingList } = state;
          const { co_host_id, status, action_type } = payload;

          // Flag to check if the co-host is already present
          let isPresent = false;

          // Create a new list by updating or removing existing entries
          const updatedList = coHostPendingList.map((coHost) => {
            if (coHost?.co_host_id === co_host_id) {
              isPresent = true;
              if (
                action_type === ZegoSignalingAction.INVITATION_REJECTED ||
                action_type === ZegoSignalingAction.INVITATION_CANCEL ||
                action_type === ZegoSignalingAction.CO_HOST_KICKOUT ||
                action_type === ZegoSignalingAction.CO_HOST_LEAVE 
              ) {
                return null; // Mark for removal
              }              
              return { ...coHost, status, action_type };
            }
            return coHost;
          }).filter(coHost => coHost !== null); // Remove marked entries
          console.log(updatedList, "update list");
          // If the co-host was not found in the list, add the new payload
          if (!isPresent) {
            console.log(updatedList, "update list in if", isPresent);
            updatedList.push(payload);
          }

          return updatedList;
        })()
      };
    case CO_HOST_SEND_PROPOSAL:
      return {
        ...state,
        isProposalSend: payload
      };
    case IS_HOST_LIVE:
      return {
        ...state,
        isHostLive: payload
      };
    case IS_SCREEN_SHARE:
      return {
        ...state,
        isScreenSharing: payload
      };
    case JOIN_USERS:
      return {
        ...state,
        joinUsers: payload
      };

    default:
      return {
        ...state,
      };
  }
};

export default common;
